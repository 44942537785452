import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const DownloadPDF = () => {
    const history = useHistory();

    useEffect(() => {
        const openPDF = () => {
            const pdfUrl = "/CoodashForAssetManagers.pdf";

            window.open(pdfUrl, "_blank");

            history.push("/");
        };

        openPDF();
    }, [history]);

    return (
        <div>
            <p>Your PDF will open shortly...</p>
        </div>
    );
};

export default DownloadPDF;
