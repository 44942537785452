import React from "react";
import { Link } from "react-router-dom";
import Img1 from "../../images/livenews1.png";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./NewsHub.module.scss";

const NewsHub = () => {
    return (
        <div className={styles.NewsHub}>
            <div style={{ height: "60px", width: "100%" }}></div>
            <Container className={styles.Container}>
                <br />
                <Row className="justify-content-center">
                    <Col xs="12">
                        <h1 className={styles.H1} align="center">
                            NewsHub
                        </h1>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <Col xs="12" sm="8">
                        <h2 className={styles.H2}>
                            A real-time digital news feed with innovative search
                            and streaming technology enabling users to browse a
                            wide range of news with filters for trending news
                            and topics.
                        </h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <div className={styles.ImgWrap}>
                        <img className={styles.Img} src={Img1} alt="newshub" />
                    </div>
                </Row>
                <Row className="justify-content-center">
                    <Col xs="12" sm="8">
                        <h2 className={styles.H2} align="center">
                            Global press
                        </h2>
                        <p className={styles.P}>
                            Coodash NewsHub is a news feeds aggregator covering
                            a range of substantive English language sources. We
                            include around 75 publishers that provide global
                            news from all sides.
                        </p>
                        <p className={styles.P}>
                            Our automated topic streams provide the latest news
                            on a wide range of subjects covering financial news,
                            country news, environment news and much more.
                        </p>
                        <p className={styles.P}>
                            Our editors curate a stream of articles in BizEdit
                            that gives an overview of the main business news and
                            financial news.
                        </p>
                        <p className={styles.P}>
                            MyNews enables users to build a custom news feed
                            based on keywords. This unique feature functions in
                            the same way as more expensive media monitoring
                            software tools and is used to build news feeds for
                            keeping track of news related to professions as well
                            as: industry news, sector news, company news, stock
                            portfolio news, crypto news etc.
                        </p>
                        <p className={styles.P}>
                            Users can pre-select their preferred news feed
                            sources.
                        </p>
                        <p className={styles.P}>
                            Articles can be bookmarked, and a full history is
                            provided for reference. It’s easy to share news
                            articles via different platforms.
                        </p>
                        <p className={styles.P}>
                            Publisher bias and fact check ratings are provided
                            by{" "}
                            <u>
                                <a
                                    className={styles.Link}
                                    href="https://mediabiasfactcheck.com/"
                                >
                                    https://mediabiasfactcheck.com/
                                </a>
                            </u>{" "}
                            with Coodash functions to filter news based on media
                            bias ratings. Users can access a balanced view of
                            news stories from different perspectives.
                        </p>
                        <p className={styles.P}>
                            NewsHub operates in the same way as a search engine
                            but also provides guidance on media paywalls; users
                            click through to the underlying site to view
                            articles (a separate subscription is required to
                            sites where publisher paywalls apply).
                        </p>
                    </Col>
                </Row>

                <div className={styles.BtnWrap}>
                    <Link className={styles.Button} to="./RegisterCoodash">
                        Sign up to NewsHub
                    </Link>
                </div>
                <br />
            </Container>
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

export default NewsHub;
