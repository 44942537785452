import Robert from "../../images/headshots/medium/robert.png";
import Lara from "../../images/headshots/medium/lara.png";
import Skippy2 from "../../images/headshots/medium/Skippy.png";
import Roger from "../../images/headshots/medium/roger.png";
import Graham from "../../images/headshots/medium/graham.png";
import Luke from "../../images/headshots/medium/luke.png";

export const imageData = [
    {
        image: Graham,
        title: "Graham",
        subtitle: "Co-founder",
        linkedinUrl: "https://www.linkedin.com/in/graham-halliday-87033722/",
    },
    {
        image: Lara,
        title: "Lara",
        subtitle: "Developer",
        linkedinUrl:
            "https://www.linkedin.com/in/lara-johnson-perkins-054989209/",
    },
    {
        image: Luke,
        title: "Luke",
        subtitle: "Developer",
        linkedinUrl: "https://www.linkedin.com/in/luke-petrocochino-16b222230/",
    },

    {
        image: Robert,
        title: "Robert",
        subtitle: "Co-founder & CTO",
        linkedinUrl: "https://www.linkedin.com/in/robertjohsonperkins/",
    },
    {
        image: Roger,
        title: "Roger",
        subtitle: "Co-founder",
        linkedinUrl: "https://www.linkedin.com/in/roger-francis-b496481a8/",
    },
    {
        image: Skippy2,
        title: "Skippy",
        subtitle: "Head of Retrieval",
        linkedinUrl: "//www.linkedin.com/company/coodash",
    },
];

export function getImages() {
    const res = {};
    for (var f = 0; f < imageData.length; f++) {
        const link = f + "-" + imageData[f].title;
        res[link] = { ...imageData[f], link };
    }
    return res;
}

export function getFirst() {
    const f = 0;
    return f + "-" + imageData[f].title;
}

export function getLinks() {
    const images = Object.values(getImages());
    const res = {};
    for (var f = 0; f < images.length; f++) {
        var prev = null;
        var next = null;
        if (f === 0) {
            prev = images[images.length - 1].link;
        } else {
            prev = images[f - 1].link;
        }
        if (f === images.length - 1) {
            next = images[0].link;
        } else {
            next = images[f + 1].link;
        }
        res[images[f].link] = { prev, next };
    }
    return res;
}
