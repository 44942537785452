export const mainSection = {
    id: "",
    dark: true,
    topLine: "",
    headline: "",
    headline2: "",
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    description5: "",
    buttonLabel: "Request demo",
    to: "/RequestDemo",
    imgStart: false,
    img: require("../../images/Broking.png").default,
    video: "",
    alt: "Main Info",
    link: "/",
};

export const objFinHub = {
    id: "FinHub",
    dark: true,
    topLine: "FinHub",
    description1: "Insight delivered.",
    description2: "Cutting-edge multi-channel delivery with live audio.",
    description3: "Connected client coverage",
    description4: "Cut through the noise",
    description5: "",
    buttonLabel: "Request demo",
    to: "/RequestDemo",
    imgStart: false,
    img: require("../../images/FinHub.png").default,
    video: "",
    alt: "FinHub",
    link: "/FinHub",
};

export const objYourHub = {
    id: "Enterprise",
    dark: false,
    topLine: "Enterprise Hubs",
    headline: "",
    headline2: "",
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    description5: "",
    buttonLabel: "Request demo",
    to: "/RequestDemo",
    imgStart: true,
    img: require("../../images/YourHub2.png").default,
    video: "",
    alt: "Enterprise",
    link: "/Enterprise",
};

export const objNewsHub = {
    id: "newsHub",
    dark: true,
    topLine: "NewsHub",
    headline: "Global Press.",
    headline2: "Search. Stream. Share.",
    description1: "Digital news aggregated in real-time.",
    description2: "",
    description3: "Smart search and topic streams.",
    description4: "",
    description5: "Design your own news feed.",
    buttonLabel: "Sign up",
    to: "/RegisterCoodash",
    imgStart: true,
    img: require("../../images/livenews1.png").default,
    video: require("../../videos/coodash.mp4").default,
    alt: "NewsHub",
    link: "/NewsHub",
};

export const objDataInsights = {
    id: "AI Integration",
    dark: false,
    topLine: "Data Insights",
    headline: "Markets Analytics.",
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    description5: "",
    buttonLabel: "Sign up",
    to: "/AI Integration",
    imgStart: true,
    img: require("../../images/dashboard2.png").default,
    video: "",
    alt: "AI Integration",
    link: "/AI Integration",
};
