import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Link as LinkRouter } from "react-router-dom";

import { GrClose } from "react-icons/gr";

import styles from "./Sidebar.module.scss";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <aside
      className={isOpen ? styles.SidebarOpen : styles.Sidebar}
      onClick={toggle}
    >
      <div className={styles.CloseIcon} onClick={toggle}>
        <GrClose className={styles.Icon} />
      </div>

      <Container className={styles.Contianer}>
        <div className={styles.BtnWrap}>
          <a className={styles.Button} href="https://finhub.coodash.com/">
            FinHub Sign Up
          </a>
        </div>
        <br />
        <Row className="justify-content-center">
          <LinkRouter className={styles.Link} to="/" onClick={toggle}>
            Home
          </LinkRouter>
        </Row>
        <br />
        <Row className="justify-content-center">
          <LinkRouter className={styles.Link} to="/Enterprise" onClick={toggle}>
            Enterprise
          </LinkRouter>
        </Row>
        <br />
        <Row className="justify-content-center">
          <LinkRouter className={styles.Link} to="/FinHub" onClick={toggle}>
            FinHub
          </LinkRouter>
        </Row>
        <br />
        <Row className="justify-content-center">
          <LinkRouter
            className={styles.Link}
            to="/AIIntegration"
            onClick={toggle}
          >
            AI Integration
          </LinkRouter>
        </Row>
        <br />

        <Row className="justify-content-center">
          <LinkRouter className={styles.Link} to="/News" onClick={toggle}>
            Press
          </LinkRouter>
        </Row>
        <br />
        <Row className="justify-content-center">
          <LinkRouter className={styles.Link} to="/Team" onClick={toggle}>
            The Team
          </LinkRouter>
        </Row>
        <br />
        <Row className="justify-content-center">
          <LinkRouter className={styles.Link} to="/FindUs" onClick={toggle}>
            Find Us
          </LinkRouter>
        </Row>
        <br />
        <Row className="justify-content-center">
          <LinkRouter className={styles.Link} to="/ContactUs" onClick={toggle}>
            Contact Us
          </LinkRouter>
        </Row>
        <br />
      </Container>
    </aside>
  );
};

export default Sidebar;
