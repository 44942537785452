import React from "react";
import Img from "../../images/EnterpriseImage.jpg";
import Img2 from "../../images/FinanceImage.jpg";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import "react-sharingbuttons/dist/main.css";

import { TwitterShareButton } from "react-share";

import styles from "./News.module.scss";

const News = () => {
  return (
    <div className={styles.AboutUs}>
      <div style={{ height: "60px", width: "100%" }}></div>
      <Container className={styles.Container}>
        <br />
        <Row className="justify-content-center">
          <Col xs="12">
            <h1 className={styles.H1} align="center">
              News
            </h1>
          </Col>
        </Row>
        <Container className={styles.Container2}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card>
                <a
                  href="https://www.tradersmagazine.com/am/making-sense-of-the-financial-communications-market/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Card.Img className={styles.img} variant="top" src={Img2} />
                </a>
                <Card.Body>
                  <Card.Title className={styles.H2}>
                    Making sense of the Financial Communications Market
                  </Card.Title>
                  <Card.Text className={styles.P}>
                    It's critical to combine the convenience of mobile chat with
                    real-time content sharing and distribution, Graham Halliday
                    of Coodash writes.
                  </Card.Text>
                  <a
                    className={styles.Button}
                    href="https://www.tradersmagazine.com/am/making-sense-of-the-financial-communications-market/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read more
                  </a>
                  <TwitterShareButton
                    url="https://www.tradersmagazine.com/am/making-sense-of-the-financial-communications-market/"
                    title="1"
                  >
                    <button className="btn btn-circle">
                      <i className="fab fa-twitter"> </i>
                    </button>
                  </TwitterShareButton>
                </Card.Body>
              </Card>
              <br />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card>
                <a
                  href="https://www.linkedin.com/pulse/whats-eating-enterprise-communications-market-coodash/?trackingId=myzCrYWR8huvIDeEvZ1aAQ%3D%3D"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Card.Img className={styles.img} variant="top" src={Img} />
                </a>
                <Card.Body>
                  <Card.Title className={styles.H2}>
                    What's eating the enterprise communications market?
                  </Card.Title>
                  <Card.Text className={styles.P}>
                    Communication tools were supposed to enhance and optimise
                    communication for traders and other financial professionals.
                    But why does every innovation seem to bring more complexity?
                    What will it take to unify communications?
                  </Card.Text>
                  <a
                    className={styles.Button}
                    href="https://www.linkedin.com/pulse/whats-eating-enterprise-communications-market-coodash/?trackingId=myzCrYWR8huvIDeEvZ1aAQ%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read more
                  </a>
                  <TwitterShareButton
                    url="https://www.linkedin.com/pulse/whats-eating-enterprise-communications-market-coodash/?trackingId=myzCrYWR8huvIDeEvZ1aAQ%3D%3D"
                    title="1"
                  >
                    <button className="btn btn-circle">
                      <i className="fab fa-twitter"> </i>
                    </button>
                  </TwitterShareButton>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default News;
