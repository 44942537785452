import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./AIIntegration.module.scss";

import Img1 from "../../images/AIIntegration.jpg";

const AIIntegration = () => {
    return (
        <div className={styles.Broadcasting}>
            <div style={{ height: "60px", width: "100%" }}></div>
            <Container className={styles.Container}>
                <br />
                <Row className="justify-content-center">
                    <Col xs="12">
                        <h1 className={styles.H1} align="center">
                            <span className={styles.Red}>AI</span> Integration
                        </h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col className={styles.SubHeading1} xs="12" sm="8">
                        <h2 className={styles.H2Top} align="center">
                            Is your business ready for AI?
                            <br />
                        </h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <div className={styles.ImgWrap}>
                        <img className={styles.Img} src={Img1} alt="Broking" />
                    </div>
                </Row>
                <br />
                <Row className="justify-content-left">
                    <Col className={styles.SubHeading2}>
                        <p className={styles.P}>
                            ChatGPT and other rapidly advancing generative AI
                            tools are everywhere and almost every business is
                            asking itself how it can apply this new tech. Whilst
                            the hype is very real, 2024 will be about finding
                            genuine use cases and implementing solutions. Much
                            of the value for companies will come from training
                            the models on their own in-house data so success
                            will depend on both the suitability and
                            accessibility of that data. <br />
                            <br />
                            Like most new technologies, successful adoption
                            requires some adjustment effort on the part of
                            users. In the case of generative AI, if you want it
                            to draw on proprietary content or knowledge, that
                            information might need a lot of processing to make
                            it ‘palatable’ in an AI training set. <br />
                            <br />
                            In July 2023 the Harvard Business Review published
                            an article which touched on this topic and provided
                            some illuminating examples (“How to Train Generative
                            AI Using Your Company’s Data” by Tom Davenport and
                            Maryam Alavi).
                            <br />
                            <br />
                            As the authors note:
                        </p>
                        <p className={styles.PBlueQuote}>
                            “knowledge within organizations is typically
                            generated and captured across various sources and
                            forms, including individual minds, processes,
                            policies, reports, operational transactions,
                            discussion boards, and online chats and meetings. As
                            such, a company’s comprehensive knowledge is often
                            unaccounted for and difficult to organize and deploy
                            where needed in an effective or efficient way.”
                        </p>
                        <br />
                        <p className={styles.P}>
                            We aren’t sure about Generative AI accessing
                            individual minds (at least not yet), but the article
                            gives a couple of brief case studies which highlight
                            the task at hand.
                        </p>
                        <br />
                        <p className={styles.PBlueQuote}>
                            “Morgan Stanley, for example, used prompt tuning to
                            train OpenAI’s GPT-4 model using a carefully curated
                            set of 100,000 documents with important investing,
                            general business, and investment process knowledge.
                            The goal was to provide the company’s financial
                            advisors with accurate and easily accessible
                            knowledge on key issues they encounter in their
                            roles advising clients. The prompt-trained system is
                            operated in a private cloud that is only accessible
                            to Morgan Stanley employees.”
                            <br />
                            <br />
                            “Morningstar, for example, used prompt tuning and
                            vector embeddings for its Mo research tool built on
                            generative AI. It incorporates more than 10,000
                            pieces of Morningstar research. After only a month
                            or so of work on its system, Morningstar opened Mo
                            usage to their financial advisors and independent
                            investor customers. It even attached Mo to a digital
                            avatar that could speak out its answers.”
                        </p>
                        <br />
                        <p className={styles.P}>
                            Getting reams of data into shape for training an AI
                            and then keeping it up to date requires changes in
                            the way things are done. Both Morgan Stanley and
                            Morningstar teach staff how best to tailor content
                            for AI consumption and even then, MS also needs a
                            dedicated team to curate content.
                            <br />
                            <br />
                            Companies have long paid lip service to the
                            importance of information management, but generative
                            AI now means they need to get serious about what
                            data they have, where it is and how accessible it
                            can be made.
                            <br />
                            <br />
                            This is where Coodash enterprise technology can play
                            an important role. Our enterprise platform
                            integrates a wide variety of information types and
                            sources into one real-time platform where it can be
                            organised and interrogated as required, both by
                            frontline staff and those training an AI.
                        </p>
                        <br />
                        <p className={styles.FurtherReading}>
                            <b>
                                <u>Further reading:</u>
                            </b>
                            <br />
                            22 Feb 2024 -{" "}
                            <a
                                className={styles.Link}
                                href="https://citywire.com/wealth-manager/news/ai-adaptation-will-be-more-challenging-than-we-think/a2436757"
                                target="_blank"
                                rel="noreferrer"
                            >
                                CW: AI adaptation will be more challenging than
                                we think
                            </a>
                            <br />
                            29 Dec 2023 –{" "}
                            <a
                                className={styles.Link}
                                href="https://www.ft.com/content/f84bd56f-484d-4393-bafc-4428da6a7873"
                                target="_blank"
                                rel="noreferrer"
                            >
                                FT: The AI revolution’s first year: has anything
                                changed?
                            </a>
                            <br />
                            06 Jul 2023 –{" "}
                            <a
                                className={styles.Link}
                                href="https://hbr.org/2023/07/how-to-train-generative-ai-using-your-companys-data"
                                target="_blank"
                                rel="noreferrer"
                            >
                                HBR: How to Train Generative AI Using Your
                                Company’s Data
                            </a>
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AIIntegration;
