import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";

import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons";

import { animateScroll as scroll } from "react-scroll";
import { Link as LinkRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import CoodashIcon from "../../images/coodash.svg";

import styles from "./Navbar.module.scss";

const Navbar = ({ isOpen, toggle }) => {
    const height = 60;

    const [show, setShow] = useState(false);

    const [navTransparent, setNavTransparent] = useState(true);

    const location = useLocation();

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY >= height || location.pathname !== "/") {
                setNavTransparent(false);
            } else {
                setNavTransparent(true);
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [location]);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <IconContext.Provider value={{ color: "#fff" }}>
            <nav
                className={
                    styles.Navbar +
                    (navTransparent ? " " + styles.Transparent : "")
                }
            >
                <div className={styles.Container}>
                    <LinkRouter
                        className={styles.Logo}
                        to="/"
                        onClick={toggleHome}
                    >
                        <img
                            className={styles.Img}
                            src={CoodashIcon}
                            alt={"Coodash Logo"}
                            height="20"
                        />
                    </LinkRouter>

                    <ul className={styles.Menu}>
                        <li className={styles.Item}>
                            <Link className={styles.Links} to="/">
                                Home
                            </Link>
                        </li>
                        <li className={styles.Item}>
                            <Link className={styles.Links} to="/Enterprise">
                                Enterprise
                            </Link>
                        </li>
                        <li className={styles.Item}>
                            <Link className={styles.Links} to="/FinHub">
                                FinHub
                            </Link>
                        </li>
                        <li className={styles.Item}>
                            <Link className={styles.Links} to="/AIIntegration">
                                AI Integration
                            </Link>
                        </li>
                        <li className={styles.Item}>
                            <Link className={styles.Links} to="/News">
                                Press
                            </Link>
                        </li>
                        <li className={styles.Item}>
                            <Link className={styles.Links} to="/Team">
                                About
                            </Link>
                        </li>
                    </ul>
                    <div className={styles.BtnWrap}>
                        <a
                            className={styles.FinButton}
                            href="https://finhub.coodash.com/SignUp"
                        >
                            FinHub - Sign Up
                        </a>
                    </div>
                    <div
                        className={
                            isOpen ? styles.MobileIconHidden : styles.MobileIcon
                        }
                        onClick={toggle}
                    >
                        <span>
                            <FaBars />
                        </span>
                        <Modal
                            show={show}
                            fullscreen={true}
                            onHide={() => setShow(false)}
                        >
                            <Modal.Body className={styles.Modal}>
                                <Row className={styles.CloseRow}>
                                    <IoMdClose
                                        onClick={() => setShow(false)}
                                        className={styles.Close}
                                    />
                                </Row>
                                <Row className="justify-content-center">
                                    <a
                                        onClick={() => setShow(false)}
                                        className={styles.FinButton}
                                        href="https://finhub.coodash.com/"
                                    >
                                        FinHub Sign Up
                                    </a>
                                </Row>
                                <br /> <br />
                                <Row className="justify-content-center">
                                    <LinkRouter
                                        onClick={() => setShow(false)}
                                        className={styles.Link}
                                        to="/"
                                    >
                                        Home
                                    </LinkRouter>
                                </Row>
                                <br />
                                <Row className="justify-content-center">
                                    <LinkRouter
                                        onClick={() => setShow(false)}
                                        className={styles.Link}
                                        to="/Enterprise"
                                    >
                                        Enterprise
                                    </LinkRouter>
                                </Row>
                                <br />
                                <Row className="justify-content-center">
                                    <LinkRouter
                                        onClick={() => setShow(false)}
                                        className={styles.Link}
                                        to="/FinHub"
                                    >
                                        FinHub
                                    </LinkRouter>
                                </Row>
                                <br />
                                <Row className="justify-content-center">
                                    <LinkRouter
                                        onClick={() => setShow(false)}
                                        className={styles.Link}
                                        to="/AIIntegration"
                                    >
                                        AI Integration
                                    </LinkRouter>
                                </Row>
                                <br />
                                <Row className="justify-content-center">
                                    <LinkRouter
                                        onClick={() => setShow(false)}
                                        className={styles.Link}
                                        to="/News"
                                    >
                                        Press
                                    </LinkRouter>
                                </Row>
                                <br />
                                <Row className="justify-content-center">
                                    <LinkRouter
                                        onClick={() => setShow(false)}
                                        className={styles.Link}
                                        to="/Team"
                                    >
                                        The Team
                                    </LinkRouter>
                                </Row>
                                <br />
                                <Row className="justify-content-center">
                                    <LinkRouter
                                        onClick={() => setShow(false)}
                                        className={styles.Link}
                                        to="/FindUs"
                                    >
                                        Find Us
                                    </LinkRouter>
                                </Row>
                                <br />
                                <Row className="justify-content-center">
                                    <LinkRouter
                                        onClick={() => setShow(false)}
                                        className={styles.Link}
                                        to="/ContactUs"
                                    >
                                        Contact Us
                                    </LinkRouter>
                                </Row>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </nav>
        </IconContext.Provider>
    );
};

export default Navbar;
