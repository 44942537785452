import React from "react";

import styles from "./InfoSectionEnterprise.module.scss";
import Fade from "react-reveal/Fade";

import { Link as LinkRouter } from "react-router-dom";

import { FaPlay } from "react-icons/fa";

const playVideo = () => {
    const vid = document.getElementById("myVideo");
    vid.play();
};

const InfoSection = ({
    id,
    dark,
    topLine,
    headline,
    headline2,
    img,
    video,
    alt,
    link,
}) => {
    return (
        <>
            <div className={styles.ContainerLight} id={id}>
                <div className={styles.Wrapper}>
                    <div className={styles.TextWrapper}>
                        <Fade top>
                            <h1 className={styles.TopLine}>{topLine}</h1>
                        </Fade>
                        <Fade top>
                            <h2
                                className={
                                    dark
                                        ? styles.HeadingLight
                                        : styles.HeadingDark
                                }
                            >
                                {headline}
                            </h2>
                        </Fade>
                        <Fade top>
                            <h2
                                className={
                                    dark
                                        ? styles.HeadingLight2
                                        : styles.HeadingDark2
                                }
                            >
                                {headline2}
                            </h2>
                        </Fade>
                        <div className={styles.Features}>
                            <div className={styles.Feature}>
                                <div className={styles.Caption}>
                                    <Fade left>
                                        A Coodash Hub enables firms to integrate
                                        all their decision critical information
                                        on a single platform. Recovering
                                        ownership and control over business
                                        intelligence from existing
                                        multi-platform setups.
                                    </Fade>
                                    <br />
                                    <Fade left>
                                        Maximising the value of incoming
                                        research and firmwide expertise.
                                    </Fade>
                                    <br />
                                    <Fade left>
                                        Enhancing cross-departmental information
                                        sharing and ensuring better informed
                                        decisions across the business.
                                    </Fade>
                                </div>
                            </div>
                            <Fade left>
                                <div className={styles.LinkTitle}>
                                    <LinkRouter
                                        className={styles.Link}
                                        to={link}
                                    >
                                        See more...
                                    </LinkRouter>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className={styles.Image}>
                        {img.length > 0 && (
                            <Fade right>
                                <img
                                    className={styles.Img}
                                    src={img}
                                    alt={alt}
                                />
                            </Fade>
                        )}
                        {video.length > 0 && (
                            <>
                                <video
                                    id="myVideo"
                                    className={styles.Img}
                                    controls
                                    src={video}
                                    type="video/mp4"
                                />
                                <div
                                    className={styles.Button}
                                    onClick={playVideo}
                                >
                                    <FaPlay />
                                    &nbsp; Play video with audio
                                </div>
                            </>
                        )}
                        <div className={styles.SignUpDiv}>
                            <br />
                            <br />
                            <br />
                            <Fade right>
                                <LinkRouter
                                    className={styles.SignUpButton}
                                    to={"/RequestDemo"}
                                >
                                    Request demo
                                </LinkRouter>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InfoSection;
