import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { FaTwitter, FaLinkedin } from "react-icons/fa";

import CoodashIcon from "../../images/coodash.svg";

import { Link } from "react-router-dom";

import styles from "./Footer.module.scss";

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <div className={styles.Footer}>
            <div className={styles.Wrap}>
                <div className={styles.LinksContainer}>
                    <vl className={styles.vl} />
                    <div className={styles.LinkItems}>
                        <h1 className={styles.LinkTitle}>Product Pages</h1>
                        <Link className={styles.Link} to="/Enterprise">
                            Enterprise
                        </Link>
                        <Link className={styles.Link} to="/FinHub">
                            FinHub
                        </Link>
                    </div>
                    <vl className={styles.vl} />
                    <div className={styles.LinkItems}>
                        <h1 className={styles.LinkTitle}>Who are we?</h1>
                        <Link className={styles.Link} to="/Team">
                            The Team
                        </Link>
                        <Link className={styles.Link} to="/FindUs">
                            Find us
                        </Link>
                        <Link className={styles.Link} to="/ContactUs">
                            Contact us
                        </Link>
                    </div>
                    <vl className={styles.vl} />
                    <div className={styles.LinkItems}>
                        <h1 className={styles.LinkTitle}>More info</h1>
                        <Link className={styles.Link} to="/RequestDemo">
                            Enterprise Demo
                        </Link>
                        <a
                            className={styles.Link}
                            href="https://finhub.coodash.com/SignUp"
                        >
                            FinHub Sign Up
                        </a>
                        <Link className={styles.Link} to="/RequestChannel">
                            FinHub Channel
                        </Link>
                    </div>
                </div>
                <section className={styles.SocialMedia}>
                    <div className={styles.SocialMediaWrap}>
                        <Link
                            className={styles.SocialLogo}
                            to="/"
                            onClick={toggleHome}
                        >
                            <img
                                className={styles.Img}
                                src={CoodashIcon}
                                alt={"Coodash Logo"}
                                height="20"
                            />
                        </Link>
                        <small className={styles.WebsiteRights}>
                            © Coodash {new Date().getFullYear()}. All rights
                            reserved.
                        </small>
                        <div className={styles.SocialIcons}>
                            <a
                                className={styles.SocialIconLink}
                                href="//www.twitter.com/coodashltd"
                                target="_blank"
                                rel="noreferrer"
                                aria-label="Twitter"
                            >
                                <FaTwitter />
                            </a>
                            <a
                                className={styles.SocialIconLink}
                                href="//www.linkedin.com/company/coodash"
                                target="_blank"
                                rel="noreferrer"
                                aria-label="Linkedin"
                            >
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Footer;
