import React from 'react';
import Refresh from '../components/Forms/RefreshToken';
import { Helmet } from 'react-helmet';

const RefreshToken = () => {
  return (
    <>
      <Helmet>
        <title>Coodash | Refresh Token</title>
        <meta name='description' content='Refresh your token for access to Coodash platform.'></meta>
      </Helmet>
      <Refresh />
    </>
  );
};

export default RefreshToken;
