import React from "react";
import { Link as LinkRouter } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./Broadcasting.module.scss";

import Img1 from "../../images/EnterpriseHub.png";
import Img2 from "../../images/DeskAggregation.PNG";
import Img3 from "../../images/DeskAggregation2.PNG";

const EnterpriseHubs = () => {
    return (
        <div className={styles.Broadcasting}>
            <div style={{ height: "60px", width: "100%" }}></div>
            <Container className={styles.Container}>
                <br />
                <Row className="justify-content-center">
                    <Col xs="12">
                        <h1 className={styles.H1} align="center">
                            Enterprise Hubs
                        </h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col className={styles.SubHeading1} xs="12" sm="8">
                        <h2 className={styles.H2Top} align="center">
                            Our enterprise technology integrates your
                            decision-critical information on one platform
                            accessible to all.
                            <br />
                            <br />
                            Innovative content management and multi-channel
                            communications features enable firms to maximise the
                            value of incoming research and firmwide expertise.
                            <br />
                            <br />
                            Enhance cross-departmental information sharing and
                            ensure better informed decisions across the
                            business.
                        </h2>
                        <div className={styles.ButtonDiv}>
                            <LinkRouter
                                className={styles.SignUpButton}
                                to={"/RequestDemo"}
                            >
                                Request demo
                            </LinkRouter>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <div className={styles.ImgWrap}>
                        <img className={styles.Img} src={Img1} alt="Broking" />
                    </div>
                </Row>
                <br />
                <Row className="justify-content-left">
                    <Col className={styles.SubHeading2}>
                        <h2 className={styles.H2}>CLIENTS</h2>
                        <p className={styles.P}>
                            Coodash enterprise technology is designed for firms
                            making decisions based on large volumes of data from
                            multiple sources. Our clients benefit from a fully
                            real-time API that unifies both structured
                            (database) and unstructured data (PDF, email, voice
                            transcript) on one easy-access platform, which they
                            control.
                        </p>
                        <br />
                        <h2 className={styles.H2}>THE IDEAL</h2>
                        <p className={styles.P}>
                            Internal strategy and intelligence drives business
                            success; the combined efforts of expensively
                            assembled teams using expensive data platforms and
                            paid-for external research.
                            <br />
                            Business intelligence needs to be easily accessible
                            and flow across the organisation to influence
                            decision-making and realise its true value. Incoming
                            content across multiple channels is valuable only if
                            it can be searched easily, retrieved quickly and
                            shared effectively across an organisation.
                            <br /> A well-defined and accessible content
                            universe provides an essential foundation for
                            integrating AI tools.
                        </p>
                        <br />
                        <h2 className={styles.H2}>THE REALITY</h2>
                        <p className={styles.P}>
                            Business intelligence comprises a range of external
                            sources, combined with internally generated research
                            and employees sharing their expertise.
                            <br /> Multiple platforms in use with data
                            accumulating via different sources and in a range of
                            formats: PDF, email, data/chat platforms, API/RSS
                            feeds, recorded meeting/minutes, CRM, websites,
                            social media, intranet etc.
                            <br /> This dislocated mass is poorly archived with
                            limited access to key documents and no record of
                            strategy formation. Document discovery mostly comes
                            down to people searching their email inboxes.
                        </p>
                        <br />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <div className={styles.ImgWrap2}>
                        <img className={styles.Img} src={Img2} alt="Broking" />
                    </div>
                </Row>
                <Row className="justify-content-left">
                    <Col className={styles.SubHeading2}>
                        <h2 className={styles.H2}>
                            This familiar setup leads to multiple issues for
                            firms.
                        </h2>
                        <br /> <br />
                        <h2 className={styles.H2Red}>Productivity</h2>
                        <p className={styles.P}>
                            Slow or failing data retrieval with no central
                            archiving or cross-platform search capability
                            results in inefficiencies and delays in
                            decision-making, as well as significant wasted time
                            for staff. <br />
                            External multi-platform setups lead to siloed teams,
                            poor information sharing and limited collaboration
                            within the business.
                            <br /> Platform access limitations create material
                            information barriers across the firm with those
                            excluded from key systems at a material
                            disadvantage. <br />
                            No central source where new staff can be easily
                            permissioned for the content they need access to.
                        </p>
                        <br />
                        <h2 className={styles.H2Red}>Control</h2>
                        <p className={styles.P}>
                            Firms have become overly dependent on external
                            platforms which can be expensive to access and over
                            which they have limited control.
                            <br /> There is poor visibility on how these
                            platforms are being used in the business; firms
                            don’t have usable records of the firmwide expertise
                            or strategy formation that is being shared via
                            different chat channels.
                            <br /> They are not in a good position in terms of
                            knowing what business content they have, where it is
                            and what access they have to it; all critical
                            foundations for developing AI tools that will
                            deliver increasing efficiencies in the future.
                        </p>
                        <br />
                        <h2 className={styles.H2Red}>Performance</h2>
                        <p className={styles.P}>
                            Not having access to all the relevant information at
                            the point of decision increases risk across the
                            business and can impact performance, either by
                            failing to realise revenue potential or by incurring
                            material losses.
                        </p>
                        <br />
                        <h2 className={styles.H2Red}>Costs</h2>
                        <p className={styles.P}>
                            Without key people working on a shared platform, the
                            firm doesn’t have a fully functioning business
                            intelligence resource. With no viable internal
                            alternative, a wider range of staff will require
                            expensive licenses to external data platforms.
                            <br /> A high functioning internal solution means
                            expensive data platform licenses can be restricted
                            to analysts and execution teams.
                            <br /> Usage of paid-for research subscriptions is
                            not adequately monitored and controlled.
                        </p>
                        <br />
                        <br />
                        <h2 className={styles.H2}>BIG PICTURE</h2>
                        <p className={styles.P}>
                            As businesses struggle with a weaker revenue
                            environment and increasing staff costs, there are
                            obvious pressures to reduce costs and improve
                            productivity.
                            <br /> Taking back control and maximising the value
                            of business intelligence enables firms to actively
                            address key issues and make a material difference to
                            the bottom-line. Just as importantly, it also lays
                            the foundations for AI tools to drive efficiencies
                            across the business.
                        </p>
                        <br />
                        <h2 className={styles.H2}>SOLUTION</h2>
                        <p className={styles.P}>
                            Coodash Co-founders have extensive experience in
                            developing front office focused data-aggregation
                            technologies. The Coodash solution is a fully
                            real-time smart API which aggregates all the
                            relevant inputs on one platform with flexible and
                            dynamic user interfaces. Coodash enterprise is for
                            companies that want to fully utilise their data
                            resources and lay the critical foundations for AI
                            implementation.
                        </p>
                    </Col>
                </Row>
                <br />
                <Row className="justify-content-center">
                    <div className={styles.ImgWrap3}>
                        <img className={styles.Img} src={Img3} alt="Broking" />
                    </div>
                </Row>
                <Row className="justify-content-center">
                    <Col className={styles.SubHeading2}>
                        <p className={styles.PNoJustify}>
                            For more information and a demo of our enterprise
                            technology:
                        </p>
                    </Col>
                </Row>

                <div className={styles.ButtonDiv2}>
                    <LinkRouter
                        className={styles.SignUpButton}
                        to={"/RequestDemo"}
                    >
                        Request demo
                    </LinkRouter>
                </div>
                <br />
            </Container>
        </div>
    );
};

export default EnterpriseHubs;
