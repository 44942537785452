import React from "react";

import styles from "./InfoSectionFinHub.module.scss";
import Fade from "react-reveal/Fade";

import { Link as LinkRouter } from "react-router-dom";

import { FaPlay } from "react-icons/fa";
import LineDivider from "../../images/LineDivider.png";

const playVideo = () => {
    const vid = document.getElementById("myVideo");
    vid.play();
};

const InfoSection = ({
    id,
    dark,
    topLine,
    headline,
    headline2,
    img,
    video,
    alt,
    link,
}) => {
    return (
        <>
            <div
                className={dark ? styles.ContainerDark : styles.ContainerLight}
                id={id}
            >
                <div className={styles.Wrapper}>
                    <div className={styles.Image}>
                        <Fade left>
                            {img.length > 0 && (
                                <img
                                    className={styles.Img}
                                    src={img}
                                    alt={alt}
                                />
                            )}
                        </Fade>
                        {video.length > 0 && (
                            <>
                                <video
                                    id="myVideo"
                                    className={styles.Img}
                                    controls
                                    src={video}
                                    type="video/mp4"
                                />
                                <div
                                    className={styles.Button}
                                    onClick={playVideo}
                                >
                                    <FaPlay />
                                    &nbsp; Play video with audio
                                </div>
                            </>
                        )}
                        <Fade left>
                            <div className={styles.SignUpDiv}>
                                <br />
                                <br />
                                <br />
                                <a
                                    className={styles.SignUpButton}
                                    href="https://finhub.coodash.com/SignUp"
                                >
                                    Sign Up
                                </a>
                            </div>
                        </Fade>
                    </div>
                    <div className={styles.TextWrapper}>
                        <Fade top>
                            <h1 className={styles.TopLine}>{topLine}</h1>
                        </Fade>
                        <Fade top>
                            <h2
                                className={
                                    dark
                                        ? styles.HeadingLight
                                        : styles.HeadingDark
                                }
                            >
                                {headline}
                            </h2>
                        </Fade>
                        <Fade top>
                            <h2
                                className={
                                    dark
                                        ? styles.HeadingLight2
                                        : styles.HeadingDark2
                                }
                            >
                                {headline2}
                            </h2>
                        </Fade>
                        <div className={styles.Features}>
                            <div className={styles.Feature}>
                                <div className={styles.Caption}>
                                    <Fade top>
                                        <div className={styles.Spacing}>
                                            Coodash FinHub is a new way for
                                            financial analysts and research
                                            companies to engage with premium
                                            subscribers and deliver a connected
                                            experience.
                                        </div>
                                    </Fade>
                                    <br />
                                    <Fade right>
                                        <span>
                                            Engage your community in{" "}
                                            <span className={styles.NoWrap}>
                                                real-time
                                            </span>
                                        </span>
                                    </Fade>
                                    <div>
                                        <img
                                            className={styles.LineDivider}
                                            src={LineDivider}
                                            alt="LineDivider"
                                        />
                                    </div>
                                    <Fade right>
                                        Live posts with audience Q&A
                                    </Fade>
                                    <div>
                                        <img
                                            className={styles.LineDivider}
                                            src={LineDivider}
                                            alt="LineDivider"
                                        />
                                    </div>
                                    <Fade right>
                                        Delayed transcripts for non-subscribers
                                    </Fade>

                                    <div>
                                        <img
                                            className={styles.LineDivider}
                                            src={LineDivider}
                                            alt="LineDivider"
                                        />
                                    </div>
                                    <Fade right>
                                        Open access web technology
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        <Fade right>
                            <div className={styles.LinkTitle}>
                                <LinkRouter
                                    className={styles.LinkFlip}
                                    to={link}
                                >
                                    See more...
                                </LinkRouter>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InfoSection;
