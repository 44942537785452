import React from "react";
import { Link, useParams } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./TeamMember.module.scss";

import { getImages, getFirst } from "./ImageData";

export function TeamMember() {
    let { id } = useParams();

    const imageData = getImages();

    if (id == null) {
        id = getFirst();
    }

    function getImage(id) {
        return imageData[id].image;
    }

    function getTitle(id) {
        return imageData[id].title;
    }

    return (
        <div className={styles.AboutUs}>
            <div style={{ height: "60px", width: "100%" }}></div>
            <Container className={styles.Container}>
                <br />
                <Row className="justify-content-center">
                    <Col xs="12">
                        <h1 className={styles.H1} align="center">
                            About Us
                        </h1>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <Col xs="12" sm="8">
                        <h2 className={styles.H2}>
                            Coodash is a content &amp; data hub with innovative
                            communications and delivery technology.
                        </h2>
                        <p className={styles.P2}>
                            Our platform is making it easy for everyone to
                            access the information they need, when and where
                            they need it.
                        </p>
                        <br />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <div className={styles.ImgWrap}>
                        <img
                            alt="Team Member"
                            className={styles.image}
                            src={getImage(id)}
                        ></img>
                        <div className={styles.titleText}>{getTitle(id)}</div>
                    </div>
                </Row>
                <br />
                <Row className="justify-content-center">
                    <Col xs="12" sm="8">
                        <p className={styles.P}>
                            Coodash founders are financial markets professionals
                            with C-Suite (SMR) and MD level management
                            experience across: COO, Trading, Risk &amp; Control,
                            Technology and Research functions.
                        </p>
                        <p className={styles.P}>
                            We have extensive experience in the design,
                            development and implementation of multi-product
                            technology solutions.
                        </p>
                        <p className={styles.P}>
                            We understand the problems our clients need
                            technology to solve, and we know the existing
                            frameworks into which our solutions will integrate.
                        </p>
                    </Col>
                </Row>
                <br />
                <div className={styles.BtnWrap}>
                    <Link className={styles.Button} to="./ContactUs">
                        Contact us
                    </Link>
                </div>
                <br />
            </Container>
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}

export default TeamMember;
