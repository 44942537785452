import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './Forms.module.scss';

const ContactUs = () => {

  const onSubmit = (e) => {
    window.dataLayer.push({ 'event': 'sign-up-click', 'test': 'test' });
  };

  return (
    <div className={styles.AboutUs}>
      <div className={styles.Wrap} classname={styles.Content}>
        <div style={{ height: '60px', width: '100%' }}>
        </div>

        <Form className={styles.Form} action="https://apps.coodash.com/form/" method="post">

          <Container>
            <br />

            <Row className="justify-content-center">
              <Col xs="12">
                <h1 align="center">Contact Us</h1>
              </Col>
            </Row>

            <br /><br />

            <Row className="justify-content-center">
              <Col x="12" sm="4">
                <Form.Label>First Name</Form.Label>
                <Form.Control required name="fname" type="text" placeholder="Enter First Name" />
              </Col>

              <Col xs="12" sm="4">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required name="lname" type="text" placeholder="Enter Last Name" />
              </Col>
            </Row>

            <br />

            <Row className="justify-content-center">
              <Col xs="12" sm="4">
                <Form.Label>Your Email</Form.Label>
                <Form.Control required name="email" type="email" placeholder="Enter Email Address" />
              </Col>

              <Col xs="12" sm="4">
                <Form.Label>Subject</Form.Label>
                <Form.Control name="subject" placeholder="Enter Subject" />
              </Col>
            </Row>

            <br />

            <Row className="justify-content-center">
              <Col xs="12" sm="8">
                <Form.Label>Company Name</Form.Label>
                <Form.Control name="company" type="text" placeholder="Enter Company Name" />
              </Col>
            </Row>

            <br />

            <Row className="justify-content-center">
              <Col xs="12" sm="8">
                <Form.Label>Message</Form.Label>
                <Form.Control required name="message" as="textarea" rows={5} placeholder="Enter Your Message" />
              </Col>
            </Row>

            <br />

            <Row className="justify-content-center">
              <Button name="action" value="contact" variant="light" type="submit" onClick={onSubmit}>
                Submit
            </Button>
            </Row>
          </Container>
        </Form>

        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

      </div>
    </div >

  )
}

export default ContactUs;