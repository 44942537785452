import React from "react";
import Hub from "../components/NewsHub/NewsHub";
import { Helmet } from "react-helmet";

const NewsHub = () => {
    return (
        <div>
            <Helmet>
                <title>Coodash | NewsHub</title>
                <meta
                    name="description"
                    content="A real-time digital news feed with innovative search and streaming tech enables NewsHub users to browse a wide range of news with filters for trending news and topics."
                ></meta>
            </Helmet>
            <Hub />
        </div>
    );
};

export default NewsHub;
