import React from 'react'
import AboutUsPage from '../components/AboutUs/AboutUs';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
    return (
        <div>
            <Helmet>
                <title>Coodash | About Us</title>
                <meta name='description' content='Coodash founders are financial markets professionals with C-Suite (SMR) and MD level management experience across: COO, Trading, Risk &amp; Control, Technology and Research functions.'></meta>
            </Helmet>
            <AboutUsPage />
        </div>
    )
}

export default AboutUs
