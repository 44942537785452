import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";

import styles from "./Forms.module.scss";

const RefreshToken = () => {
    return (
        <div className={styles.Contact}>
            <div className={styles.Wrap}>
                <div style={{ height: "60px", width: "100%" }}></div>
                <div classname={styles.Content}>
                    <Form
                        className={styles.Form}
                        action="https://apps.coodash.com/form/"
                        method="post"
                    >
                        <Container>
                            <br />
                            <Row className="justify-content-center">
                                <Col xs="12">
                                    <h1 align="center">Refresh Token</h1>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col xs="12" sm="8">
                                    <p align="center" className={styles.P}>
                                        If you are already signed up to Coodash
                                        and would like to refresh your token for
                                        access to the platform, please enter
                                        your email below and we will send you a
                                        new one.
                                    </p>
                                    <p align="center" className={styles.P}>
                                        If you don't hear from us, you might
                                        need to enter your details to sign up
                                        again{" "}
                                        <u>
                                            <Link
                                                className={styles.Link}
                                                to="/RegisterCoodash"
                                            >
                                                here
                                            </Link>
                                        </u>
                                        .
                                    </p>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col xs="12" sm="8">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        name="email"
                                        type="email"
                                        placeholder="Enter Email Address"
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row className="justify-content-center">
                                <Button
                                    name="action"
                                    value="refresh"
                                    variant="light"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Row>
                        </Container>
                    </Form>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    );
};

export default RefreshToken;
