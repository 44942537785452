import React from "react";
import { Link } from "react-router-dom";
import Img from "../../images/livenews2.png";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./AboutUs.module.scss";

const AboutUs = () => {
    return (
        <div className={styles.AboutUs}>
            <div style={{ height: "60px", width: "100%" }}></div>
            <Container className={styles.Container}>
                <br />
                <Row className="justify-content-center">
                    <Col xs="12">
                        <h1 className={styles.H1} align="center">
                            About Us
                        </h1>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <Col xs="12" sm="8">
                        <h2 className={styles.H2}>
                            Coodash is a content &amp; data hub with innovative
                            communications and delivery technology.
                        </h2>
                        <p className={styles.P2}>
                            Our platform is making it easy for everyone to
                            access the information they need, when and where
                            they need it.
                        </p>
                        <br />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <div className={styles.ImgWrap}>
                        <img className={styles.Img} src={Img} alt="livenews" />
                    </div>
                </Row>
                <br />
                <Row className="justify-content-center">
                    <Col xs="12" sm="8">
                        <p className={styles.P}>
                            Coodash founders are financial markets professionals
                            with C-Suite (SMR) and MD level management
                            experience across: COO, Trading, Risk &amp; Control,
                            Technology and Research functions.
                        </p>
                        <p className={styles.P}>
                            We have extensive experience in the design,
                            development and implementation of multi-product
                            technology solutions.
                        </p>
                        <p className={styles.P}>
                            We understand the problems our clients need
                            technology to solve, and we know the existing
                            frameworks into which our solutions will integrate.
                        </p>
                    </Col>
                </Row>
                <br />
                <div className={styles.BtnWrap}>
                    <Link className={styles.Button} to="./ContactUs">
                        Contact us
                    </Link>
                </div>
                <br />
            </Container>
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

export default AboutUs;
