import React from 'react'
import { Helmet } from 'react-helmet';
import Rotate from 'react-reveal/Rotate';


const PageNotFound = () => {
    return (
        <div>
            <Helmet>
                <title>Coodash | Error 404</title>
                <meta name='description' content='Page not found.'></meta>
            </Helmet>

            <br /><br />
            <div align="center">
                <Rotate>
                    <img src="/logoerror.png" alt="Coodash Logo" style={{ width: "100px" }} />
                </Rotate>
                <br /><br />
                <h1>
                    404
                </h1>

                <h2>
                    Oops... This page doesn't exist!
                </h2>
                <br /><br /><br /><br />
            </div>
        </div>
    )
}

export default PageNotFound
