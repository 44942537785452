import React from 'react'
import TeamPage from '../components/Team/Team';
import { Helmet } from 'react-helmet';

const Team = () => {
    return (
        <div>
            <Helmet>
                <title>Coodash | Coodash Team</title>
                <meta name='description' content='Coodash founders are financial markets professionals with C-Suite (SMR) and MD level management experience across: COO, Trading, Risk &amp; Control, Technology and Research functions.'></meta>
            </Helmet>
            <TeamPage />
        </div>
    )
}

export default Team
