import React from "react";
import Register from "../components/Forms/RegisterCoodash";
import { Helmet } from "react-helmet";

const RegisterCoodash = () => {
    return (
        <>
            <Helmet>
                <title>Coodash | Sign up to NewsHub</title>
                <meta name="description" content="Sign up to NewsHub."></meta>
            </Helmet>
            <Register />
        </>
    );
};

export default RegisterCoodash;
