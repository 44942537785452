import React from 'react'
import FindUsPage from '../components/FindUs/FindUs';
import { Helmet } from 'react-helmet';

const FindUs = () => {
    return (
        <div>
            <Helmet>
                <title>Coodash | Find Us </title>
                <meta name='description' content='Find us.'></meta>
            </Helmet>
            <FindUsPage />
        </div>
    )
}

export default FindUs
