import React from "react";
import FinHub from "../components/FinHub/FinHub";
import { Helmet } from "react-helmet";

const FinHubPage = () => {
    return (
        <div>
            <Helmet>
                <title>Coodash | FinHub</title>
                <meta
                    name="description"
                    content="Coodash content hub technology offers an exciting new way to deliver real-time content to clients simultaneously."
                ></meta>
            </Helmet>
            <FinHub />
        </div>
    );
};

export default FinHubPage;
