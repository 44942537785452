import React from "react";
import Img from "../../images/ScreenCapStaticOpaque.png";
import { Link as LinkRouter } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./Broadcasting.module.scss";

const FinHub = () => {
    return (
        <div className={styles.Broadcasting}>
            <div style={{ height: "60px", width: "100%" }}></div>
            <Container className={styles.Container}>
                <br />
                <Row className="justify-content-center">
                    <Col xs="12">
                        <h1 className={styles.H1} align="center">
                            FinHub
                        </h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col className={styles.SubHeading} xs="12" sm="8">
                        <h2 className={styles.H2} align="center">
                            Coodash FinHub is the open platform making fully
                            <span className={styles.NoWrap}>
                                {" "}
                                real-time
                            </span>{" "}
                            financial markets technology accessible for
                            everyone.
                            <br />
                            <br />
                            News, commentary and subscription research channels
                            all on one easy access web platform.
                        </h2>
                        <div className={styles.ButtonDiv}>
                            <LinkRouter
                                className={styles.SignUpButton}
                                to={"/RequestChannel"}
                            >
                                Request Channel
                            </LinkRouter>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <div className={styles.ImgWrap}>
                        <img className={styles.Img} src={Img} alt="Broking" />
                    </div>
                </Row>
                <Row className="justify-content-center">
                    <Col xs="12" sm="8">
                        <p className={styles.P}>
                            Research companies and analysts can now provide{" "}
                            <span className={styles.NoWrap}>real-time</span>{" "}
                            client coverage with our web distribution
                            technology, reaching those who don't have access to
                            professional financial markets platforms and
                            ultimately, opening up a whole new universe of
                            engaged clients.
                        </p>
                        <p className={styles.P}>
                            FinHub is the single point of access solution to the
                            research user’s problem of email newsletter
                            overload, social media carnage and proliferating
                            external websites. With so much free content on
                            social platforms, FinHub technology materially
                            upgrades your professional subscription product by
                            enabling a coverage intensity that cannot be
                            replicated with other delivery mediums.
                        </p>
                        <p className={styles.P}>
                            As our network expands, so will the potential to
                            grow through finding new clients amongst the FinHub
                            community.
                        </p>
                    </Col>
                </Row>
                <br />
                <Row className="justify-content-center">
                    <Col xs="12" sm="8">
                        <div className={styles.Side}>
                            <h3 className={styles.Title}>
                                Innovative delivery:
                            </h3>
                            <ul className={styles.BulletList}>
                                <li className={styles.li}>
                                    Live posts with audience Q&A
                                </li>
                                <li className={styles.li}>
                                    Images/ Charts/ Links/ Docs/ Forwards all in{" "}
                                    <span className={styles.NoWrap}>
                                        real-time
                                    </span>
                                </li>
                                <li className={styles.li}>
                                    Multiple analysts posting 24hrs from any
                                    location
                                </li>
                                <li className={styles.li}>
                                    Permissioned access for subscription
                                    services
                                </li>
                                <li className={styles.li}>
                                    Delayed daily transcripts for
                                    non-subscribers
                                </li>
                                <li className={styles.li}>
                                    Re-playable audio squawk channels
                                </li>
                                <li className={styles.li}>
                                    Compatible with corporate firewalls
                                </li>
                            </ul>
                        </div>
                        <div className={styles.Side}>
                            <h3 className={styles.Title}>
                                Designed for the user:
                            </h3>
                            <ul className={styles.BulletList}>
                                <li className={styles.li}>
                                    <span className={styles.NoWrap}>
                                        Real-time
                                    </span>{" "}
                                    Redbox Financial News
                                </li>
                                <li className={styles.li}>
                                    FinX (formerly Twitter)
                                </li>
                                <li className={styles.li}>
                                    Aggreagated Financial RSS
                                </li>
                                <li className={styles.li}>
                                    Free access via secure login and password
                                </li>
                                <li className={styles.li}>
                                    Position multiple channels in desktop format
                                    with pop-outs
                                </li>
                                <li className={styles.li}>
                                    Fast keyword search and topic tags
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FinHub;
