import React from "react";

import { objFinHub, objYourHub } from "../components/InfoSection/Data";

import HeroSection from "../components/HeroSection/HeroSection";
import InfoSectionFinHub from "../components/InfoSection/InfoSectionFinHub";
import InfoSectionEnterprise from "../components/InfoSection/InfoSectionEnterprise";
import DataInsights from "../components/InfoSection/DataInsights";
import { objDataInsights } from "../components/InfoSection/Data";

import Products from "../components/Products/Products";
import { Helmet } from "react-helmet";

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Coodash | Home</title>
                <meta
                    name="description"
                    content="Coodash is a content &amp; data hub with innovative communications and delivery technology."
                ></meta>
            </Helmet>
            <HeroSection />
            <Products />
            <InfoSectionEnterprise {...objYourHub} />
            <InfoSectionFinHub {...objFinHub} />
            <DataInsights {...objDataInsights} />
        </>
    );
};

export default Home;
