import React from 'react'
import Contact from '../components/Forms/ContactUs';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
    return (
        <div>
            <Helmet>
                <title>Coodash | Contact Us</title>
                <meta name='description' content='Contact us.'></meta>
            </Helmet>
            <Contact />
        </div>
    )
}

export default ContactUs
