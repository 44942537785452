import React from "react";
import { Link } from "react-router-dom";

import { FaLinkedin } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./Team.module.scss";
import { getImages } from "./ImageData";

function Team() {
    return (
        <div className={styles.AboutUs}>
            <div style={{ height: "60px", width: "100%" }}></div>
            <Container className={styles.Container}>
                <br />
                <Row className="justify-content-center">
                    <Col xs="12">
                        <h1 className={styles.H1} align="center">
                            Coodash Team
                        </h1>
                    </Col>
                </Row>
                <div>
                    <Container fluid className={styles.gallery}>
                        <Row align="center" className={styles.Row}>
                            {Object.values(getImages()).map((item) => (
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={4}
                                    xl={4}
                                    className={styles.Col}
                                >
                                    <img
                                        alt="Team Member"
                                        className={styles.galleryimage}
                                        src={item.image}
                                    ></img>
                                    <div
                                        className={styles.gallerytitles}
                                        align="center"
                                    >
                                        {item.title}
                                    </div>
                                    <div
                                        className={styles.gallerysubtitles}
                                        align="center"
                                    >
                                        {item.subtitle}
                                    </div>
                                    <div
                                        className={styles.linkedin}
                                        align="center"
                                    >
                                        <a
                                            className={styles.SocialIconLink}
                                            href={item.linkedinUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label="Linkedin"
                                        >
                                            <FaLinkedin
                                                className={styles.linkedin}
                                            />
                                        </a>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>

                <br />
                <Row className="justify-content-center">
                    <Col xs="12" sm="6">
                        <p className={styles.P}>
                            <br />
                            <br />
                            Coodash founders are financial markets professionals
                            with C-Suite (SMR) and MD level management
                            experience across: COO, Trading, Risk &amp; Control,
                            Technology and Research functions.
                        </p>
                        <p className={styles.P}>
                            We have extensive experience in the design,
                            development and implementation of multi-product
                            technology solutions.
                        </p>
                        <p className={styles.P}>
                            We understand the problems our clients need
                            technology to solve, and we know the existing
                            frameworks into which our solutions will integrate.
                        </p>
                    </Col>
                </Row>
                <br />
                <div className={styles.BtnWrap}>
                    <Link className={styles.Button} to="./ContactUs">
                        Contact us
                    </Link>
                </div>
                <br />
            </Container>
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}

export default Team;
