import React from "react";
import Video from "../../videos/coodash_bg.mp4";

import styles from "./HeroSection.module.scss";
import Fade from "react-reveal/Fade";

const playVideo = () => {
    //  var vid = document.getElementById("bg-video");
    //  vid.play()
};

const HeroSection = () => {
    return (
        <div className={styles.HeroSection}>
            <div className={styles.Bg}>
                <video
                    id="bg-video"
                    className={styles.VideoBg}
                    autoPlay
                    loop
                    muted
                    src={Video}
                    type="video/mp4"
                />
            </div>
            <div className={styles.Gradient} onClick={playVideo}></div>

            <div className={styles.Content}>
                <div className={styles.TextContainerLeft}>
                    <Fade left>
                        <h1 className={styles.H1}>
                            Enterprise Intelligence Hubs{" "}
                        </h1>
                    </Fade>
                    <Fade right>
                        <h1 className={styles.H2}>
                            Empowering your information in{" "}
                            <span className={styles.RedText}>real-time</span>
                        </h1>
                    </Fade>
                </div>

                <Fade right>
                    <div className={styles.TextContainerRight}>
                        <span className={styles.CoodashCool}>Coodash</span>{" "}
                        makes fully{" "}
                        <span className={styles.NoWrap}>real-time</span>{" "}
                        financial markets technology accessible for everyone.
                        <div className={styles.DivSpace} />
                        Whether it's analysts providing client coverage, or
                        companies integrating their decision-critical data on{" "}
                        <b>one platform</b>, we enable <b>your digital edge </b>
                        by making business intelligence{" "}
                        <span className={styles.NoWrap}>real-time.</span>
                    </div>
                </Fade>
            </div>
        </div>
    );
};

export default HeroSection;
