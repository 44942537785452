import Icon2 from "../../images/FinHub.png";
import Icon4 from "../../images/YourHub.png";

import { Link as LinkScroll } from "react-scroll";

import styles from "./Products.module.scss";
import Fade from "react-reveal/Fade";

const Products = () => {
    return (
        <div className={styles.Products} id="products">
            <Fade bottom>
                <h1 className={styles.H1}>Our Products</h1>
            </Fade>

            <div className={styles.Wrapper}>
                <Fade left>
                    <LinkScroll
                        to="Enterprise"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={0}
                    >
                        <div className={styles.Card}>
                            <img
                                className={styles.Icon}
                                src={Icon4}
                                alt="dashboard"
                            />
                            <h2 className={styles.H2}>Enterprise Hubs</h2>
                            <p className={styles.P}>
                                Intelligence platform drives informed decisions
                                across the business.
                            </p>
                        </div>
                    </LinkScroll>
                </Fade>
                <Fade right>
                    <LinkScroll
                        to="FinHub"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={0}
                    >
                        <div className={styles.Card}>
                            <img
                                className={styles.Icon}
                                src={Icon2}
                                alt="Broking"
                            />
                            <h2 className={styles.H2}>FinHub</h2>
                            <p className={styles.P}>
                                Open access platform for financial analysts
                                delivering subscription research.
                            </p>
                        </div>
                    </LinkScroll>
                </Fade>
            </div>
        </div>
    );
};

export default Products;
