import React from "react";

import styles from "./InfoSectionDataInsights.module.scss";
import Fade from "react-reveal/Fade";
import { Link as LinkRouter } from "react-router-dom";

import LineDivider from "../../images/LineDivider.png";
import ThickDivider from "../../images/ThickDivider.png";

const DataInsights = ({ id, dark, headline2 }) => {
    return (
        <>
            <div
                className={dark ? styles.ContainerDark : styles.ContainerLight}
            >
                <div className={styles.Wrapper}>
                    <div className={styles.TextWrapper}>
                        <div className={styles.RealtimeData}>
                            <Fade top>
                                <h1
                                    className={
                                        dark
                                            ? styles.HeadingLight
                                            : styles.HeadingDark
                                    }
                                >
                                    <span className={styles.RedText}>
                                        Real-time
                                    </span>{" "}
                                    data insights
                                </h1>
                                <h4
                                    className={
                                        dark
                                            ? styles.SubHeadingLight
                                            : styles.SubHeadingDark
                                    }
                                >
                                    Innovative content management and
                                    multi-channel communications.
                                </h4>
                            </Fade>
                            <Fade top>
                                <h2
                                    className={
                                        dark
                                            ? styles.HeadingLight2
                                            : styles.HeadingDark2
                                    }
                                >
                                    {headline2}
                                </h2>
                            </Fade>
                            <div className={styles.Features}>
                                <div className={styles.Feature}>
                                    <div className={styles.Caption}>
                                        <Fade left>
                                            <div className={styles.Bullets}>
                                                Flexible interfaces connect all
                                                source data
                                            </div>
                                        </Fade>
                                        <div className={styles.LineDividerDiv}>
                                            <img
                                                className={styles.LineDivider}
                                                src={LineDivider}
                                                alt="LineDivider"
                                            />
                                        </div>
                                        <Fade left>
                                            <div className={styles.Bullets}>
                                                Central archiving with fast
                                                search
                                            </div>
                                        </Fade>
                                        <div className={styles.LineDividerDiv}>
                                            <img
                                                className={styles.LineDivider}
                                                src={LineDivider}
                                                alt="LineDivider"
                                            />
                                        </div>
                                        <Fade left>
                                            <div className={styles.Bullets}>
                                                Channel setup aligns to business
                                                structure
                                            </div>
                                        </Fade>
                                        <div className={styles.LineDividerDiv}>
                                            <img
                                                className={styles.LineDivider}
                                                src={LineDivider}
                                                alt="LineDivider"
                                            />
                                        </div>
                                        <Fade left>
                                            <div className={styles.Bullets}>
                                                Live analyst interactions with
                                                user Q&A
                                            </div>
                                        </Fade>
                                        <div className={styles.LineDividerDiv}>
                                            <img
                                                className={styles.LineDivider}
                                                src={LineDivider}
                                                alt="LineDivider"
                                            />
                                        </div>
                                        <Fade left>
                                            <div className={styles.Bullets}>
                                                Audio squawk connects remote
                                                workers
                                            </div>
                                        </Fade>
                                        <div className={styles.LineDividerDiv}>
                                            <img
                                                className={styles.LineDivider}
                                                src={LineDivider}
                                                alt="LineDivider"
                                            />
                                        </div>
                                        <Fade left>
                                            <div className={styles.Bullets}>
                                                Easy access web technology
                                                (FDC3-enabled)
                                            </div>
                                        </Fade>
                                        <div className={styles.LineDividerDiv}>
                                            <img
                                                className={styles.LineDivider}
                                                src={LineDivider}
                                                alt="LineDivider"
                                            />
                                        </div>
                                        <Fade left>
                                            <div
                                                className={styles.Bullets}
                                                id={id}
                                            >
                                                Low maintenance implementations
                                                deployed quickly
                                            </div>
                                        </Fade>
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*//////////////////////////////////////////////////////////////////////////////////////////*/}
                <div className={styles.WrapperAfter}>
                    <div className={styles.TextWrapperAfter}>
                        <Fade top>
                            <h1
                                className={
                                    dark
                                        ? styles.HeadingLight
                                        : styles.HeadingDarkAfter
                                }
                            >
                                <span className={styles.RedText}>AI</span>{" "}
                                Integration{" "}
                            </h1>
                            <h4 className={styles.SubHeadingDarkAfter}>
                                Future-proofing business intelligence.
                            </h4>
                        </Fade>
                        <img
                            src={ThickDivider}
                            alt="ThickDivider"
                            className={styles.ThickDivider}
                        />
                        <Fade top>
                            <h2
                                className={
                                    dark
                                        ? styles.HeadingLight2
                                        : styles.HeadingDark2
                                }
                            >
                                {headline2}
                            </h2>
                        </Fade>
                        <div className={styles.FeaturesAfter}>
                            <div className={styles.FeatureAfter}>
                                <div className={styles.Caption}>
                                    <Fade right>
                                        <div
                                            className={styles.FirstBulletAfter}
                                        >
                                            A well-defined and accessible
                                            universe of relevant content
                                            provides the essential foundations
                                            for drawing valuable insights from
                                            integrated AI tools.
                                        </div>
                                    </Fade>
                                    <Fade right>
                                        <div className={styles.BulletsAfter}>
                                            Firms need to determine what content
                                            they have, where it is and how
                                            accessible it can be made.
                                        </div>
                                    </Fade>
                                    <Fade right>
                                        <div className={styles.BulletsAfter}>
                                            Successful adoption can require
                                            adjustments to existing processes so
                                            that content works for AI models.
                                        </div>
                                    </Fade>
                                    <Fade right>
                                        <div className={styles.BulletsAfter}>
                                            The wider productivity benefits will
                                            be significant. On a practical
                                            level, being able to automate
                                            pulling of relevant extracts,
                                            generating summaries and enabling
                                            Q&A will be a major efficiency
                                            benefit to all.
                                        </div>
                                    </Fade>
                                    <br />
                                    <Fade right>
                                        <div className={styles.LinkTitle}>
                                            <LinkRouter
                                                className={styles.Link2}
                                                to={"/AIIntegration"}
                                            >
                                                See more...
                                            </LinkRouter>
                                        </div>
                                    </Fade>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*//////////////////////////////////////////////////////////////////////////////////////////*/}
                <div className={styles.Wrapper}>
                    <div className={styles.TextWrapperTech}>
                        <Fade top>
                            <h1
                                className={
                                    dark
                                        ? styles.HeadingLight
                                        : styles.HeadingDark
                                }
                            >
                                Smart Technology
                            </h1>
                            <h4 className={styles.SubHeadingDark}>
                                Fully{" "}
                                <span className={styles.CoodashHot}>
                                    real-time
                                </span>{" "}
                                aggregation of large{" "}
                                <span className={styles.NoWrap}>real-time</span>{" "}
                                data sets.
                                <br />
                                <span className={styles.SmallerText}>
                                    <br />A single API for multiple sources with
                                    data stored in the most performant
                                    technology (e.g, high volume, big data,
                                    audio, documents etc.)
                                    <br />
                                    <div className={styles.LineBreak} />
                                    Data is displayed using a flexible, modern
                                    web stack and FDC3-enabled front-end
                                </span>
                            </h4>
                        </Fade>
                        <Fade top>
                            <h2
                                className={
                                    dark
                                        ? styles.HeadingLight2
                                        : styles.HeadingDark2
                                }
                            >
                                {headline2}
                            </h2>
                        </Fade>
                        <div className={styles.Features}>
                            <div className={styles.Feature}>
                                <div className={styles.Caption}>
                                    <Fade left>
                                        <div className={styles.Bullets}>
                                            Fast non-blocking data ingress
                                        </div>
                                    </Fade>
                                    <div className={styles.LineDividerDiv}>
                                        <img
                                            className={styles.LineDivider}
                                            src={LineDivider}
                                            alt="LineDivider"
                                        />
                                    </div>
                                    <Fade left>
                                        <div className={styles.Bullets}>
                                            <span className={styles.NoWrap}>
                                                Real-time
                                            </span>{" "}
                                            iterative aggregations
                                        </div>
                                    </Fade>
                                    <div className={styles.LineDividerDiv}>
                                        <img
                                            className={styles.LineDivider}
                                            src={LineDivider}
                                            alt="LineDivider"
                                        />
                                    </div>
                                    <Fade left>
                                        <div className={styles.Bullets}>
                                            Single simple query language &
                                            shared queries
                                        </div>
                                    </Fade>
                                    <div className={styles.LineDividerDiv}>
                                        <img
                                            className={styles.LineDivider}
                                            src={LineDivider}
                                            alt="LineDivider"
                                        />
                                    </div>
                                    <Fade left>
                                        <div className={styles.Bullets}>
                                            Horizontally scalable
                                        </div>
                                    </Fade>
                                    <div className={styles.LineDividerDiv}>
                                        <img
                                            className={styles.LineDivider}
                                            src={LineDivider}
                                            alt="LineDivider"
                                        />
                                    </div>
                                    <Fade left>
                                        <div className={styles.Bullets}>
                                            Database & Cache agnostic
                                        </div>
                                    </Fade>
                                    <div className={styles.LineDividerDiv}>
                                        <img
                                            className={styles.LineDivider}
                                            src={LineDivider}
                                            alt="LineDivider"
                                        />
                                    </div>
                                    <Fade left>
                                        <div className={styles.Bullets}>
                                            Cloud, Private Cloud or on Prem
                                            (Azure, AWS or Google)
                                        </div>
                                    </Fade>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*//////////////////////////////////////////////////////////////////////////////////////////*/}
                <div className={styles.WrapperAfter}>
                    <div className={styles.TextWrapperAfter}>
                        <Fade top>
                            <h1
                                className={
                                    dark
                                        ? styles.HeadingLight
                                        : styles.HeadingDarkAfter
                                }
                            >
                                Domain Expertise
                            </h1>
                        </Fade>
                        <img
                            src={ThickDivider}
                            alt="ThickDivider"
                            className={styles.ThickDivider}
                        />
                        <div className={styles.FeaturesAfter}>
                            <div className={styles.FeatureAfter}>
                                <div className={styles.Caption}>
                                    <Fade right>
                                        <div className={styles.BulletsAfter}>
                                            <span
                                                className={styles.CoodashCool}
                                            >
                                                Coodash
                                            </span>{" "}
                                            founders are market experts with
                                            extensive experience in the design,
                                            development and implementation of
                                            financial markets technologies.
                                        </div>
                                    </Fade>
                                    <Fade right>
                                        <div className={styles.BulletsAfter}>
                                            Business domain insight means they
                                            understand client’s problems and
                                            they know the existing frameworks
                                            into which Coodash solutions must
                                            integrate.
                                        </div>
                                    </Fade>
                                    <Fade right>
                                        <div className={styles.BulletsAfter}>
                                            This significant knowledge and
                                            experience is inherent in the
                                            Coodash platform.
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DataInsights;
