import React from "react";
import Channel from "../components/Forms/RequestChannel";
import { Helmet } from "react-helmet";

const RequestChannel = () => {
    return (
        <>
            <Helmet>
                <title>FinHub | Request Channel</title>
                <meta name="description" content="Request channel."></meta>
            </Helmet>
            <Channel />
        </>
    );
};

export default RequestChannel;
