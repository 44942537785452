import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import RegisterCoodash from "./pages/RegisterCoodash";
import RefreshToken from "./pages/RefreshToken";
import RequestDemo from "./pages/RequestDemo";
import RequestChannel from "./pages/RequestChannel";
import FindUsPage from "./pages/FindUs";
import Contact from "./pages/ContactUs";
import AboutUsPage from "./pages/AboutUs";
import NewsPage from "./pages/News";
import TeamPage from "./pages/Team";
import TeamMemberPage from "./pages/TeamMember";
import Hub from "./pages/NewsHub";
import FinHub from "./pages/FinHub";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import ScrollToTop from "./components/ScrollToTop";
import PageNotFound from "./pages/PageNotFound";
import DownloadPDF from "./pages/DownloadPDF";
import EnterpriseHubs from "./components/Enterprise/EnterpriseHubs";
import AIIntegration from "./components/AIIntegration/AIIntegration";

function App() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Router basename={"/"}>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar isOpen={isOpen} toggle={toggle} />
            <Switch>
                <Route path="/" component={Home} exact />
                <Route
                    path="/RegisterCoodash"
                    component={RegisterCoodash}
                    exact
                />
                <Route path="/RefreshToken" component={RefreshToken} exact />
                <Route path="/RequestDemo" component={RequestDemo} exact />
                <Route
                    path="/RequestChannel"
                    component={RequestChannel}
                    exact
                />
                <Route path="/FindUs" component={FindUsPage} exact />
                <Route path="/ContactUs" component={Contact} exact />
                <Route path="/AboutUs" component={AboutUsPage} exact />
                <Route path="/News" component={NewsPage} exact />
                <Route path="/Team" component={TeamPage} exact />
                <Route path="/TeamMember" component={TeamMemberPage} exact />
                <Route path="/NewsHub" component={Hub} exact />
                <Route path="/FinHub" component={FinHub} exact />
                <Route path="/Enterprise" component={EnterpriseHubs} exact />
                <Route
                    path="/CoodashForAssetManagers"
                    component={DownloadPDF}
                    exact
                />
                <Route path="/AIIntegration" component={AIIntegration} exact />
                <Route component={PageNotFound} exact />
            </Switch>
            <Footer />
        </Router>
    );
}

export default App;
