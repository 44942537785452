import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import HoverImage from "react-hover-image";
import CoodashMarker from "../../images/coodash-marker.svg";
import ExpandMarkerHover from "../../images/expand-marker.svg";

import styles from "./FindUs.module.scss";

const AnyReactComponent = ({ text }) => {
    return (
        <div className={styles.MarkerLocation}>
            <a
                href="https://www.google.com/maps/place/160+Fleet+St,+London+EC4A+2DQ/@51.5144821,-0.110248,17z/data=!3m1!4b1!4m5!3m4!1s0x487604b2d0468f8d:0x81c03c3ca23ec3b3!8m2!3d51.5144821!4d-0.108054"
                target="_blank"
                rel="noreferrer"
            >
                <HoverImage
                    src={CoodashMarker}
                    hoverSrc={ExpandMarkerHover}
                    href="https://apps.coodash.com"
                    alt="Coodash"
                />
            </a>
        </div>
    );
};

class FindusPage extends Component {
    static defaultProps = {
        center: {
            lat: 51.5145,
            lng: -0.10805936620709387,
        },
        zoom: 16,
    };

    render() {
        return (
            <div className={styles.FindUs}>
                <div className={styles.Wrap}>
                    <div style={{ height: "60px", width: "100%" }}></div>
                    <div style={{ height: "1000px", width: "100%" }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: "AIzaSyADa3ri5hA6OwbsnaZLRIOW4TJ1aLFkx5U",
                            }}
                            defaultCenter={this.props.center}
                            defaultZoom={this.props.zoom}
                        >
                            <AnyReactComponent
                                lat={51.5145}
                                lng={-0.10805936620709387}
                                text="Coodash"
                            />
                        </GoogleMapReact>
                    </div>

                    <br />
                    <h2 className={styles.Label} align="center">
                        160 Fleet Street <br /> London <br /> EC4A 2DQ
                    </h2>
                    <br />
                </div>
            </div>
        );
    }
}

export default FindusPage;
