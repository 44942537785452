import React from "react";
import NewsPage from "../components/News/News";
import { Helmet } from "react-helmet";

const News = () => {
    return (
        <div>
            <Helmet>
                <title>Coodash | Press</title>
                <meta
                    name="description"
                    content="Coodash founders are financial markets professionals with C-Suite (SMR) and MD level management experience across: COO, Trading, Risk &amp; Control, Technology and Research functions."
                ></meta>
            </Helmet>
            <NewsPage />
        </div>
    );
};

export default News;
