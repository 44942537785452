import React from 'react';
import Demo from '../components/Forms/RequestDemo';
import { Helmet } from 'react-helmet';

const RequestDemo = () => {
    return (
        <>
        <Helmet>
                <title>Coodash | Request Demo</title>
                <meta name='description' content='Request demo.'></meta>
            </Helmet>
          <Demo />  
        </>
    );
};

export default RequestDemo;
